import { defineComponent, PropType } from 'vue'
import { Device } from '~/utils'
import './index.scss'
import { ClientOnly, CommonContainer } from '#components'
export const moduleBaseProps = {
  name: String,
  desktopBackground: String,
  mobileBackground: String,
  image_pc: String,
  image_mobile: String,
  backgroundVideo: String,
  video: String,
  videoPoster: String
}
const ContainerProps = {
  containerClassName: null as unknown as PropType<unknown>,
  paddingX: {
    type: Boolean,
    default: true
  },
  paddingY: {
    type: Boolean,
    default: true
  }
}
export default defineComponent({
  name: 'ModuleBase',
  props: {
    ...moduleBaseProps,
    ...ContainerProps,
    hasVideo: Boolean,
    className: null as unknown as PropType<unknown>, // 自定义类名
    dynamicHeight: Boolean // 是否动态高度，区域高度将由内容撑开
  },
  setup(props, { slots }) {
    const moduleBaseClass = computed(() => {
      const customClasses = props.className instanceof Array ? props.className : [props.className]
      return [props.dynamicHeight && 'com-module-dynamic', 'com-module', ...customClasses]
    })

    return () => (
      <section class={moduleBaseClass.value}>
        {(props.desktopBackground || props.mobileBackground) && (
          <div class={'com-module-cover'}>
            {props.desktopBackground && (
              <img class={['com-module-cover-img', 'desktop']} src={props.desktopBackground} alt={props.name} />
            )}
            {props.mobileBackground && (
              <img class={['com-module-cover-img', 'mobile']} src={props.mobileBackground} alt={props.name} />
            )}
          </div>
        )}
        <ClientOnly>
          {props.hasVideo && !Device().isMobile && props.video && (
            <video class={'com-module-video'} playsinline autoplay muted loop poster={props.videoPoster}>
              <source src={props.video} type="video/mp4" />
            </video>
          )}
        </ClientOnly>
        <CommonContainer className={props.containerClassName}>{slots.default?.()}</CommonContainer>
      </section>
    )
  }
})
